// TODO move all of this to one external JS file that is shared between UI layer and business logic

export const DefaultPage = Object.freeze({
  CREATE_PROFILE_FIRST_PARTY: 'createProfile',
  LOG_IN: 'logIn',
  COMPLETE_PROFILE: 'completeProfile',
  ADD_SHIPPING_ADDRESS: 'addShippingAddress',
  CREATE_PROFILE_EMAIL_ONLY: 'createProfileEmailOnly',
  ENTER_ONE_TIME_CODE_AUTH: 'enterOneTimeCode',
  LOGIN_EMAIL_ONLY_FORK: 'loginEmailOnlyFork',
})

export const SdkMessageType = Object.freeze({
  CONFIGURATION: 'configuration',
  PROFILE: 'profile',
  RESIZE: 'resize',
  ANALYTICS_PAGE_LOAD: 'analytics_page_load_event',
  ANALYTICS_CLICK: 'analytics_click_event',
  ANALYTICS_ERROR: 'analytic_error_event',
  NATIVE_COMMAND: 'native_command',
  API: 'api',
  STORE_OPT_IN_AGREEMENT_IDENTIFIERS: 'store_opt_in_agreement_identifiers',
  LOG: 'log',
  CLOSE: 'close',
  CROSS_APP: 'crossapp',
  TOKEN: 'token',
  CROSS_APP_LOGIN: 'crossapp_login',
  CROSS_APP_LOGIN_COMPLETED: 'crossapp_login_completed',
  TELEPHONE_FORMAT_ON_ENTER: 'telephone_format_on_enter',
  TELEPHONE_FORMAT_ON_DELETE: 'telephone_format_on_delete',
  MASK_EMAIL: 'mask_email',
  NAVIGATION: 'navigation',
  LOGOUT: 'logout',
  BIRTH_YEAR_RANGE: 'birth_year_range',
  OAUTH_REDIRECT: 'oauth_redirect',
  VERIFY_PROFILE_COMPLETION_STATUS: 'verify-profile-completion-status',
  LOGOUT_AND_CLOSE: 'logout_and-close',
  PASSKEY_CREATE_CREDS: 'passkey_create_creds',
  PASSKEY_CREATE_CREDS_RESPONSE: 'passkey_create_creds_response',
  PASSKEY_AUTH_CREDS: 'passkey_auth_creds',
  PASSKEY_LOADING: 'passkey_loading',
  PASSKEY_AUTH_CREDS_RESPONSE: 'passkey_auth_creds_response',
  BRAND_LOGOS: 'brand_logos',
})

export const SdkMessageCategory = Object.freeze({
  REQUEST: 'request',
  RESPONSE: 'response',
})

export const errorMessages = Object.freeze({
  NOT_ALLOWED: 'NotAllowedError',
})

export const NativeCommandType = Object.freeze({
  OPEN_BROWSER: 'browser_open',
  GOOGLE_AUTHENTICATION: 'google_authentication',
  APPLE_AUTHENTICATION: 'apple_authentication',
  OVERLAY_CLOSE: 'overlay_close',
  GLOBAL_MESSAGE: 'global_message',
  GET_SMARTLOCK_CREDENTIALS: 'get_smartlock_credentials',
})

export const CloseReason = Object.freeze({
  AUTOMATIC: 'automatic',
  CLOSE_BUTTON: 'close_button',
  EXPLORE_BUTTON: 'explore_button',
  MAYBE_LATER_BUTTON: 'maybe_later_button',
})

export const IdmApiEndpointType = Object.freeze({
  REGISTER: 'register',
  LOGIN: 'login',
  GOOGLE_LOGIN: 'google_login',
  GOOGLE_REGISTER: 'google_register',
  APPLE_LOGIN: 'apple_login',
  APPLE_REGISTER: 'apple_register',
  EMAIL_VALIDATION: 'email_validation',
  UPDATE_PROFILE: 'update_profile',
  GET_PROFILE: 'get_profile',
  REQUEST_ONE_TIME_CODE: 'request_one_time_code',
  ONE_TIME_CODE_LOGIN: 'one_time_code_login',
  EMAIL_ONLY_REGISTER: 'email_only_register',
  UPDATE_VPPA_CONSENT: 'update_vppa_consent',
})

export const cookieNames = Object.freeze({
  PARK_FR: 'park-fr',
  PARK_FR_ACCESS: 'park-fr-access',
  PARK_FR_REFRESH: 'park-fr-refresh',
  I_PLANET_DIRECTORY_PRO: 'iPlanetDirectoryPro',
})

export const userPorcessStatusNames = Object.freeze({
  COMPLETE_PROFILE_COMPLETION_STATUS: 'COMPLETE_PROFILE_COMPLETION_STATUS',
  SIGN_UP_COMPLETION_STATUS: 'SIGN_UP_COMPLETION_STATUS',
})

export function createApiRequestMessage(type, params) {
  return ({ endpoint: type, requestParams: params })
}

export function createApiResponseMessage(type, success, error, json) {
  return ({ endpoint: type, response: { success, error, json } })
}

export function createZeroBounceApiResponseMessage(type, emailStatus, isEmailValid) {
  return ({ endpoint: type, emailStatus, isEmailValid })
}

export function setProfileCompletionStatus(value) {
  localStorage.setItem(userPorcessStatusNames.COMPLETE_PROFILE_COMPLETION_STATUS, value)
}

export function setSignUpCompletionStatus(value) {
  localStorage.setItem(userPorcessStatusNames.SIGN_UP_COMPLETION_STATUS, value)
}

export function getProfileCompletionStatus() {
  return localStorage.getItem(userPorcessStatusNames.COMPLETE_PROFILE_COMPLETION_STATUS)
}

export function getSignUpCompletionStatus() {
  return localStorage.getItem(userPorcessStatusNames.SIGN_UP_COMPLETION_STATUS)
}

export function createOneTimeCodeAuthResponseMessage(type, success, error, json) {
  return ({ endpoint: type, response: { success, error, json } })
}

export function generateRandomGuid(baseText) {
  return `${baseText}${new Date().getMilliseconds().toString()}`
}
