// Need to disable this rule to keep signature
/* eslint-disable class-methods-use-this */

import {
  createApiResponseMessage,
  createZeroBounceApiResponseMessage,
  IdmApiEndpointType,
  NativeCommandType,
  SdkMessageCategory,
  SdkMessageType,
  ConfigHelpers,
  createOneTimeCodeAuthResponseMessage,
} from 'sdk-ui-web-library-common'
import Logger from './util/logger'
import {
  AnalyticsErrorSystem,
  AnalyticsErrorType,
  AnalyticsEventDispatcher,
  AnalyticsService,
  ClickLocation,
  EmailValidationStatus,
  FirstPartyDataInputField,
  getTextAndCursorPositionOnTelephoneDelete,
  getTextAndCursorPositionOnTelephoneEnter,
  maskEmail,
  getBirthYearOptions,
  OpenType,
  PageName,
  SignInType,
  AuthenticationFlow,
  AuthenticationNavigationStateMachine,
  validateEmailLocally,
} from './networking/kmp'
import { convertEnumToCloneableObject, createComputedPropertyLessClone } from './helpers/jsonHelper'
import { AuthenticationState, RegistrationResult } from './consts'
import { getNativeBridge } from './nativeBridge'
import { buildProfile } from './helpers/credentialHelper'
import { isUserMissingRequiredOptIns } from './helpers/cookieHelper'
import { isPasskeySupported } from './helpers/passkeyHelper'
import {
  fixAuthenticationFlow,
  fixClickLocation,
  fixErrorSystem,
  fixErrorType,
  fixPageName,
  fixSignInType,
} from './helpers/kmp'

export default class IdentityWindow {
  constructor(props = {}) {
    this.language = props.language
    this.logger = new Logger('Identity-UILayer')
    this.logger.enabled = props.debug
    this.native = props.native
    if (this.native) this.registerBridgeSubscriptions()
    this.url = null
    this.config = null
    this.launchDarklyConfiguration = null
    this.idmApiClient = null
    this.crossDomain = false
    this.identity = null

    if (props.config) {
      this.config = props.config
    }
    if (props.launchDarklyConfiguration) {
      this.launchDarklyConfiguration = props.launchDarklyConfiguration
    }
    if (props.onMessage) {
      this.onMessageCallback = props.onMessage
    }
    if (props.onAuthenticationStateChange) {
      this.onAuthenticationStateChangeCallback = props.onAuthenticationStateChange
    }
    if (props.onRegistrationResult) {
      this.onRegistrationResultCallback = props.onRegistrationResult
    }
    if (props.crossDomain) {
      this.crossDomain = props.crossDomain
    }
    if (props.identity) {
      this.identity = props.identity
    }

    // make 'this' always refer to IdentityWindow, regardless of where onMessage is called from
    this.onMessage = this.onMessage.bind(this)
  }

  setReferralInfo(registrationReferrer, referringPage) {
    AnalyticsEventDispatcher.init(registrationReferrer, referringPage)
  }

  enableMessaging() {
    this.logger.log('IdentityWindow messaging enabled')
    window.addEventListener('message', this.onMessage)
  }

  disableMessaging() {
    window.removeEventListener('message', this.onMessage)
  }

  setIdmApiClient(idmApiClient) {
    this.logger.log('IDM API client set')
    this.idmApiClient = idmApiClient
  }

  setTarget(target) {
    this.logger.log('Target set:', target)
    this.targetOrigin = target
  }

  setConfig(config) {
    this.config = config
  }

  setLanguage(language) {
    this.language = language
  }

  setNative(native) {
    this.native = native
    if (this.native) this.registerBridgeSubscriptions()
    else this.unregisterBridgeSubscriptions()
  }

  setCrossDomain(crossDomain) {
    this.crossDomain = crossDomain
  }

  updateProfile(profile) {
    this.#notifyWindowOfProfileStateChange(profile)
  }

  getWindow() {
    return window
  }

  registerBridgeSubscriptions() {
    getNativeBridge().then((bridge) => {
      if (!bridge) return

      const notifyWindowOfNativeCommand = (data) => {
        const window = this.getWindow()
        if (window) {
          window.postMessage({
            type: SdkMessageType.NATIVE_COMMAND,
            category: SdkMessageCategory.RESPONSE,
            value: data,
          }, this.targetOrigin)
        }
      }

      if (!this.commandResultSubscriptionRef) {
        this.commandResultSubscriptionRef = bridge.subscribe(({ subject, message }) => {
          notifyWindowOfNativeCommand({ subject, message })
        }, 'CommandResult')
      }

      if (!this.commandSubscriptionRef) {
        this.commandSubscriptionRef = bridge.subscribe(({ subject, message }) => {
          notifyWindowOfNativeCommand({ subject, message })
        }, 'Command')
      }

      if (!this.eventSubscriptionRef) {
        this.eventSubscriptionRef = bridge.subscribe(({ subject, message }) => {
          notifyWindowOfNativeCommand({ subject, message })
        }, 'Event')
      }
    })
  }

  unregisterBridgeSubscriptions() {
    if (this.commandResultSubscriptionRef) {
      this.commandResultSubscriptionRef()
      this.commandResultSubscriptionRef = null
    }
    if (this.commandSubscriptionRef) {
      this.commandSubscriptionRef()
      this.commandSubscriptionRef = null
    }
    if (this.eventSubscriptionRef) {
      this.eventSubscriptionRef()
      this.eventSubscriptionRef = null
    }
  }

  onMessage(event) {
    // TODO perform target/source validation later
    // if (
    //  event.origin !== this._targetOrigin ||
    //  !event.data ||
    //  event.data.source !== 'identity'
    // ) return;
    if (event.data.type && event.data.category === SdkMessageCategory.REQUEST &&
      event.origin === this.targetOrigin) {
      if (event.data.type === SdkMessageType.CONFIGURATION) {
        this.configureWindow()
      }

      if (event.data.type === SdkMessageType.ANALYTICS_PAGE_LOAD) {
        const pageName = fixPageName(event.data.value)
        AnalyticsEventDispatcher.sendPageLoaded(pageName)
        if (pageName.name === PageName.CREATE_PROFILE_THIRD_PARTY_GOOGLE.name ||
          pageName.name === PageName.CREATE_PROFILE_THIRD_PARTY_APPLE.name ||
          pageName.name === PageName.CREATE_PROFILE_FIRST_PARTY.name) {
          AnalyticsService.markUserEnteredSignUpFlow()
        } else if (pageName.name === PageName.COMPLETE_PROFILE_CREATE.name ||
          pageName.name === PageName.COMPLETE_PROFILE_LOGIN.name) {
          AnalyticsService.markUserEnteredCompleteProfileFlow()
        }
      }

      if (event.data.type === SdkMessageType.ANALYTICS_CLICK) {
        const { clickEvent, args = [] } = event.data.value
        const pageName = fixPageName(event.data.value.pageName)
        if (typeof clickEvent === 'string') {
          AnalyticsEventDispatcher.updatePageName(pageName)
          AnalyticsEventDispatcher.sendClickEventValuesWithArgs(
            clickEvent,
            'Navigation',
            Array.isArray(args) ? args : [args],
          )
        } else {
          AnalyticsEventDispatcher.sendClickEventWithArgs(
            fixClickLocation(clickEvent),
            pageName,
            Array.isArray(args) ? args : [args],
          )
        }
      }

      if (event.data.type === SdkMessageType.ANALYTICS_ERROR) {
        const { pageName, description, errorSystem, errorType, zeroBounceStatus } = event.data.value
        AnalyticsEventDispatcher.logErrorWithPageName(
          fixPageName(pageName),
          description,
          fixErrorType(errorType),
          fixErrorSystem(errorSystem),
          zeroBounceStatus?.status,
        )
      }

      if (event.data.type === SdkMessageType.CROSS_APP_LOGIN_COMPLETED) {
        AnalyticsEventDispatcher.updatePageName(PageName.CROSS_AUTHENTICATION)
        AnalyticsEventDispatcher.sendOAuthAuthenticationSuccess()
      }

      if (event.data.type === SdkMessageType.LOG) {
        const { message } = event.data.value
        Logger.logWithDatadog(`[Identity-Core] ${JSON.parse(message)}`)
      }

      if (event.data.type === SdkMessageType.NATIVE_COMMAND) {
        const { commandType, payload } = event.data.value
        this.processNativeRequest(commandType, payload)
      }

      if (event.data.type === SdkMessageType.API) {
        const { endpoint, requestParams } = event.data.value
        this.processApiRequest(endpoint, requestParams)
      }

      if (event.data.type === SdkMessageType.TELEPHONE_FORMAT_ON_DELETE ||
        event.data.type === SdkMessageType.TELEPHONE_FORMAT_ON_ENTER) {
        const { cursorIndex, text } = event.data.value
        const result = event.data.type === SdkMessageType.TELEPHONE_FORMAT_ON_DELETE
          ? getTextAndCursorPositionOnTelephoneDelete(cursorIndex, text)
          : getTextAndCursorPositionOnTelephoneEnter(cursorIndex, text)
        const newText = result.text
        const newCursorPosition = result.cursorPosition
        const window = this.getWindow()
        if (window) {
          window.postMessage({
            type: event.data.type,
            category: SdkMessageCategory.RESPONSE,
            value: { newText, newCursorPosition },
          }, this.targetOrigin)
        }
      }

      if (event.data.type === SdkMessageType.MASK_EMAIL) {
        const { email } = event.data.value
        const maskedEmail = maskEmail(email)
        const window = this.getWindow()
        if (window) {
          window.postMessage({
            type: event.data.type,
            category: SdkMessageCategory.RESPONSE,
            value: { maskedEmail },
          }, this.targetOrigin)
        }
      }

      if (event.data.type === SdkMessageType.OAUTH_REDIRECT) {
        const { email } = event.data.value
        const window = this.getWindow()
        if (window) {
          window.postMessage({
            type: event.data.type,
            category: SdkMessageCategory.RESPONSE,
            value: { email },
          }, this.targetOrigin)
        }
      }

      if (event.data.type === SdkMessageType.BIRTH_YEAR_RANGE) {
        const { currentYear } = event.data.value
        const years = getBirthYearOptions(currentYear)
        const window = this.getWindow()
        if (window) {
          window.postMessage({
            type: event.data.type,
            category: SdkMessageCategory.RESPONSE,
            value: { years },
          }, this.targetOrigin)
        }
      }

      if (event.data.type === SdkMessageType.NAVIGATION) {
        this.#processNavigationRequest(event.data.value)
      }

      if (event.data.type === SdkMessageType.LOGOUT) {
        const { pageName } = event.data.value

        this.identity?.unauthenticate(
          pageName.pageEventName,
          pageName.pageType.pageTypeEventName,
        )
        window.postMessage({
          type: SdkMessageType.PROFILE,
          category: SdkMessageCategory.RESPONSE,
          value: null,
        }, this.targetOrigin)
      }

      if (event.data.type === SdkMessageType.BRAND_LOGOS) {
        this.#getBrandLogos()
      }
    }

    if (this.onMessageCallback) this.onMessageCallback(event)
  }

  async #getBrandLogos() {
    const logos = await this.identity.getBrandLogos()
    const iframeWindow = this.getWindow()
    if (iframeWindow) {
      iframeWindow.postMessage({
        type: SdkMessageType.BRAND_LOGOS,
        category: SdkMessageCategory.RESPONSE,
        value: { logos },
      }, this.targetOrigin)
    }
  }

  configureWindow() {
    const iframeWindow = this.getWindow()
    if (iframeWindow) {
      this.launchDarklyConfiguration.refresh()
      iframeWindow.postMessage({
        type: SdkMessageType.CONFIGURATION,
        category: SdkMessageCategory.RESPONSE,
        value: {
          file: this.config,
          launchDarkly: {
            configuration: this.launchDarklyConfiguration.configuration,
            FirstPartyDataInputField: convertEnumToCloneableObject(FirstPartyDataInputField),
          },
          mParticle: {
            PageName: convertEnumToCloneableObject(PageName),
            OpenType: convertEnumToCloneableObject(OpenType),
            ClickLocation: convertEnumToCloneableObject(ClickLocation),
            SignInType: convertEnumToCloneableObject(SignInType),
            AnalyticsErrorSystem: convertEnumToCloneableObject(AnalyticsErrorSystem),
            AnalyticsErrorType: convertEnumToCloneableObject(AnalyticsErrorType),
          },
          model: {
            AuthenticationFlow: convertEnumToCloneableObject(AuthenticationFlow),
          },
          debugEnabled: this.logger.enabled,
          language: this.language,
          native: this.native,
          isCrossDomain: this.crossDomain,
          isRunningTests: !!window.Cypress,
        },
      }, this.targetOrigin)
    }
  }

  processNativeRequest(command, payload) {
    switch (command) {
      case NativeCommandType.GOOGLE_AUTHENTICATION:
        getNativeBridge().then((bridge) => {
          if (!bridge) {
            console.error('Bridge not initialized')
            return
          }
          if (!window.nativeWrapper.os) {
            console.error('nativeWrapper does not have an OS')
            return
          }

          const clientId = this.config.google[window.nativeWrapper.os.toLowerCase()]?.clientId

          // Fire sign out event so that user gets prompted to select email/sign in with new user
          bridge.sendCommand('GoogleSignOut', { clientId })
          bridge.sendCommand('GoogleSignIn', { clientId })
        })
        break
      case NativeCommandType.APPLE_AUTHENTICATION:
        getNativeBridge().then((bridge) => {
          if (!bridge) {
            console.error('Bridge not initialized')
            return
          }

          bridge.sendCommand('OverlayOpen', {
            url: payload.redirectUri,
          })
        })
        break
      case NativeCommandType.GLOBAL_MESSAGE:
        getNativeBridge().then((bridge) => {
          if (!bridge) {
            console.error('Bridge not initialized')
            return
          }

          bridge.sendCommand('GlobalMessage', payload)
        })
        break
      case NativeCommandType.OVERLAY_CLOSE:
        getNativeBridge().then((bridge) => {
          if (!bridge) {
            console.error('Bridge not initialized')
            return
          }

          if (window.nativeWrapper?.os === 'iOS') {
            // The old SDK has a short delay to run the close command on iOS
            setTimeout(() => bridge.sendCommand('OverlayClose'), 1000)
          } else {
            bridge.sendCommand('OverlayClose')
          }
        })
        break
      case NativeCommandType.OPEN_BROWSER:
        getNativeBridge().then((bridge) => {
          if (!bridge) {
            console.error('Bridge not initialized')
            return
          }

          bridge.sendCommand('BrowserOpen', { url: payload.url })
        })
        break
      case NativeCommandType.GET_SMARTLOCK_CREDENTIALS:
        getNativeBridge().then((bridge) => {
          if (!bridge) {
            console.error('Bridge not initialized')
            return
          }

          if (window.nativeWrapper.os !== 'Android') return // Only android has SmartLock
          bridge.sendCommand('DisableAutoSignIn')
          bridge.sendCommand('GetStoredCredentials')
        })
        break
      default:
        break
    }
  }

  async processApiRequest(endpoint, requestParams) {
    const sessionResponseHandler = this.#handeSessionResponse(endpoint)
    const profileResponseHandler = this.#handleProfileResponse(endpoint)
    switch (endpoint) {
      case IdmApiEndpointType.REGISTER:
        try {
          const responseJson = await this.idmApiClient.register(requestParams)
          sessionResponseHandler({ success: true, responseJson })
        } catch (error) {
          this.logger.log(error)
          sessionResponseHandler({ success: false, error })
        }
        break
      case IdmApiEndpointType.LOGIN:
        try {
          const responseJson = await this.idmApiClient.login(requestParams)
          sessionResponseHandler({ success: true, responseJson })
        } catch (error) {
          this.logger.log(error)
          sessionResponseHandler({ success: false, error })
        }
        break
      case IdmApiEndpointType.GOOGLE_REGISTER: {
        try {
          const key = window.nativeWrapper?.os ?? 'web'
          const clientType = this.config.getGoogleClientType(key)
          const responseJson = await this.idmApiClient.registerGoogle({
            ...requestParams,
            clientType,
          })
          sessionResponseHandler({ success: true, responseJson })
        } catch (error) {
          this.logger.log(error)
          sessionResponseHandler({ success: false, error })
        }
        break
      }
      case IdmApiEndpointType.GOOGLE_LOGIN: {
        try {
          const key = window.nativeWrapper?.os ?? 'web'
          const clientType = this.config.getGoogleClientType(key)
          const responseJson = await this.idmApiClient.loginGoogle({
            ...requestParams,
            clientType,
          })
          sessionResponseHandler({ success: true, responseJson })
        } catch (error) {
          this.logger.log(error)
          sessionResponseHandler({ success: false, error })
        }
        break
      }
      case IdmApiEndpointType.APPLE_REGISTER:
        try {
          const responseJson = await this.idmApiClient.registerApple(requestParams)
          sessionResponseHandler({ success: true, responseJson })
        } catch (error) {
          this.logger.log(error)
          sessionResponseHandler({ success: false, error })
        }
        break
      case IdmApiEndpointType.APPLE_LOGIN:
        try {
          const responseJson = await this.idmApiClient.loginApple(requestParams)
          sessionResponseHandler({ success: true, responseJson })
        } catch (error) {
          this.logger.log(error)
          sessionResponseHandler({ success: false, error })
        }
        break
      case IdmApiEndpointType.EMAIL_VALIDATION: {
        const window = this.getWindow()
        const sendPostMessage = (value) => {
          if (window) {
            window.postMessage({
              type: SdkMessageType.API,
              category: SdkMessageCategory.RESPONSE,
              value,
            }, this.targetOrigin)
          }
        }
        if (this.launchDarklyConfiguration.configuration.isZeroBounceEnabled) {
          const zeroBounceResponseHandler = ({ emailStatus, isEmailValid }) => {
            const value = createZeroBounceApiResponseMessage(
              endpoint,
              createComputedPropertyLessClone(emailStatus),
              isEmailValid,
            )
            sendPostMessage({ ...value, currentField: requestParams?.currentField })
          }
          try {
            const emailStatus = await this.idmApiClient.validateEmail(requestParams)
            const isEmailValid = emailStatus.isValid()
            zeroBounceResponseHandler({ emailStatus, isEmailValid })
          } catch (error) {
            this.logger.log(error)
            zeroBounceResponseHandler({ emailStatus: new EmailValidationStatus('unknown'), isEmailValid: false })
          }
        } else {
          const value = validateEmailLocally(requestParams.email)
          sendPostMessage({
            isEmailValid: value,
            endpoint: IdmApiEndpointType.EMAIL_VALIDATION,
            currentField: requestParams?.currentField,
          })
        }
        break
      }
      case IdmApiEndpointType.GET_PROFILE:
        try {
          const responseJson = await this.idmApiClient.getProfile()
          profileResponseHandler({ success: true, responseJson })
        } catch (error) {
          this.logger.log(error)
          profileResponseHandler({ success: false, error })
        }
        break
      case IdmApiEndpointType.UPDATE_PROFILE:
        try {
          const responseJson = await this.idmApiClient.updateProfile(requestParams)
          profileResponseHandler({ success: true, responseJson })
        } catch (error) {
          this.logger.log(error)
          profileResponseHandler({ success: false, error })
        }
        break
      case IdmApiEndpointType.REQUEST_ONE_TIME_CODE: {
        const oneTimeCodeAuthResponseHandler = ({ success, error, responseJson }) => {
          const value = createOneTimeCodeAuthResponseMessage(
            endpoint,
            success,
            error,
            responseJson,
          )
          const window = this.getWindow()
          if (window) {
            window.postMessage({
              type: SdkMessageType.API,
              category: SdkMessageCategory.RESPONSE,
              value,
            }, this.targetOrigin)
          }
        }
        try {
          const responseJson = await this.idmApiClient.requestOneTimeCode(requestParams)
          oneTimeCodeAuthResponseHandler({ success: true, responseJson })
        } catch (error) {
          this.logger.log(error)
          oneTimeCodeAuthResponseHandler({ success: false, error })
        }
      }
        break
      case IdmApiEndpointType.UPDATE_VPPA_CONSENT:
        try {
          await this.idmApiClient.updateVPPAConsent(requestParams)
          this.#notifyWindowOfAuthenticationResponse({ endpoint, success: true, error: null })
        } catch (error) {
          this.logger.log(error)
          this.#notifyWindowOfAuthenticationResponse({
            endpoint,
            success: false,
            error: error ? createComputedPropertyLessClone(error) : error,
          })
        }
        break
      case IdmApiEndpointType.ONE_TIME_CODE_LOGIN:
        try {
          const responseJson = await this.idmApiClient.loginWithOneTimeCode(requestParams)
          sessionResponseHandler({ success: true, responseJson })
        } catch (error) {
          this.logger.log(error)
          sessionResponseHandler({ success: false, error })
        }
        break
      case IdmApiEndpointType.EMAIL_ONLY_REGISTER:
        try {
          const responseJson = await this.idmApiClient.registerEmailOnly(requestParams)
          profileResponseHandler({ success: true, notifyProfileChanged: false, responseJson })
        } catch (error) {
          this.logger.log(error)
          profileResponseHandler({ success: false, error })
        }
        break
      default:
        break
    }
  }

  async #processNavigationRequest({
    pageName,
    authFlow,
    signInType,
    shouldOpenAddShippingAddress,
    shouldOpenCompleteProfile,
  }) {
    const pageNameKt = fixPageName(pageName)
    const authFlowKt = fixAuthenticationFlow(authFlow)
    const signInTypeKt = fixSignInType(signInType)

    const isMissingRequiredOptIns = isUserMissingRequiredOptIns(
      this.config,
      this.identity?.profile?._id,
    )

    const shouldPromptForPasskey = (await isPasskeySupported()) && this.crossDomain
    const machine = AuthenticationNavigationStateMachine.construct()

    const nextPageName = machine.getNextPageInFlow(
      pageNameKt,
      authFlowKt,
      signInTypeKt,
      shouldPromptForPasskey,
      shouldOpenAddShippingAddress,
      shouldOpenCompleteProfile,
      isMissingRequiredOptIns ?? false,
    )

    const window = this.getWindow()
    if (window) {
      window.postMessage({
        type: SdkMessageType.NAVIGATION,
        category: SdkMessageCategory.RESPONSE,
        value: { pageName: createComputedPropertyLessClone(nextPageName) },
      }, this.targetOrigin)
    }
  }

  #handeSessionResponse(endpoint) {
    return ({ success, error, responseJson }) => {
      let profile = null
      if (success) {
        profile = buildProfile(responseJson.profile, ConfigHelpers.getConfigOptIns(this.config))

        if (this.onAuthenticationStateChangeCallback) {
          this.onAuthenticationStateChangeCallback({
            authenticationState: AuthenticationState.AUTHENTICATED, profile,
          })
        }
        if (this.onRegistrationResultCallback) {
          const result = this.#convertSuccessfulApiResponseToRegistrationResult(endpoint)
          if (result) this.onRegistrationResultCallback({ registrationResult: result })
        }
        this.#notifyWindowOfProfileStateChange(profile)
      }

      this.#notifyWindowOfAuthenticationResponse({
        endpoint,
        success,
        profile,
        error: error ? createComputedPropertyLessClone(error) : error,
      })
    }
  }

  #handleProfileResponse(endpoint) {
    return ({ success, error, responseJson, notifyProfileChanged = true }) => {
      let profile = null
      if (success) {
        profile = buildProfile(responseJson, ConfigHelpers.getConfigOptIns(this.config))
        if (this.onAuthenticationStateChangeCallback && notifyProfileChanged) {
          this.onAuthenticationStateChangeCallback({ profile })
        }

        if (this.onRegistrationResultCallback) {
          const result = this.#convertSuccessfulApiResponseToRegistrationResult(endpoint)
          if (result) this.onRegistrationResultCallback({ registrationResult: result })
        }

        if (notifyProfileChanged) {
          this.#notifyWindowOfProfileStateChange(profile)
        }
      }

      this.#notifyWindowOfAuthenticationResponse({
        endpoint,
        success,
        profile,
        error: error ? createComputedPropertyLessClone(error) : error,
      })
    }
  }

  #convertSuccessfulApiResponseToRegistrationResult(endpoint) {
    switch (endpoint) {
      case IdmApiEndpointType.REGISTER:
      case IdmApiEndpointType.GOOGLE_REGISTER:
      case IdmApiEndpointType.APPLE_REGISTER:
        return RegistrationResult.REGISTERED
      case IdmApiEndpointType.LOGIN:
      case IdmApiEndpointType.GOOGLE_LOGIN:
      case IdmApiEndpointType.APPLE_LOGIN:
        return RegistrationResult.LOGGED_IN
      case IdmApiEndpointType.UPDATE_PROFILE:
        return RegistrationResult.COMPLETED
      default:
        return null
    }
  }

  #notifyWindowOfProfileStateChange(profile) {
    const window = this.getWindow()
    if (window) {
      window.postMessage({
        type: SdkMessageType.PROFILE,
        category: SdkMessageCategory.RESPONSE,
        value: profile,
      }, this.targetOrigin)
    }
  }

  #notifyWindowOfAuthenticationResponse({ endpoint, success, error, profile }) {
    const window = this.getWindow()
    if (window) {
      window.postMessage({
        type: SdkMessageType.API,
        category: SdkMessageCategory.RESPONSE,
        value: createApiResponseMessage(endpoint, success, error, profile),
      }, this.targetOrigin)
    }
  }
}
